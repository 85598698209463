/* Styles specific to the about section */

.mission,
.background {
	@extend %narrow-section;
}

.passions {
	background-color: rgba($dark, 0.05);
	margin: 5% -5.55%;
	padding: 5%;
}

.things-I-love {
	@extend %flex-list;
	@include wide-section;

	li {
		background-color: rgba($dark, 0.05);
		border-radius: 1rem;
		padding: 2rem 1.5rem;
	}
}

.fun-fact {
	text-align: center;
}

.favorite-colors {
	@extend %flex-list;
	justify-content: center;
	max-width: min(380px, 90vmin);
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 710px) {
		max-width: max(640px, 70vmin);
	}

	li {
		border-radius: 50% 0;
		flex: 0 1 8%;
		padding: 1.5rem 2rem;
		width: 50%;

		&:nth-of-type(odd) {
			border-radius: 0 50%;
		}

		&:hover {
			h4,
			p {
				opacity: 1;
			}
		}
	}

	h4 {
		text-transform: capitalize;
	}

	h4,
	p {
		margin: 0.8rem 0;
		opacity: 0;
		transition: opacity 300ms ease;
	}
}
