/* Extends and mixins go here */

/* Create extendable styles for a flex-styled list, horizontal by default. */
%narrow-section {
  @media screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 680px;
  }
}

/* Create extendable styles for a flex-styled list, horizontal by default. */
@mixin wide-section() {
  @media screen and (min-width: 1400px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;
  }
}

/* Create extendable styles for a flex-styled list, horizontal by default. */
%flex-list {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -0.5rem;
  padding: 0;

  li {
    display: unset;
    flex: 1 1 auto;
    margin: 0.5rem;
    padding: 0 2vmin;
    text-align: center;

    &.print-only {
      display: none;
    }
  }
}

/* Helper function for colors list */
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

/* Helper function to test the darkness of a background color */
@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return $dark; // Lighter backgorund, return dark color
  } @else {
    @return $light; // Darker background, return light color
  }
}

/* Clean up box-shadows */
@mixin box-shadow($color: $dark) {
  box-shadow: 0 0 15px 0 rgba($color, 0.5);
}

@mixin inset-box-shadow($color: $dark) {
  box-shadow: inset 0 0 15px 0 rgba(scale-color($color, $lightness: -18%), 0.5);
}

/* Create extendable styles for a small border above/below an element. */
@mixin dotted-border($color: $dark, $position: after) {
  &:#{$position} {
    border-top: 1vmin dotted $color;
    content: "";
    display: block;
    margin: calc(1rem + 3vmin) auto;
    opacity: 0.7;
    width: 5vmin;
  }
}