/* Define variables here */

@font-face {
  font-family: 'Rouge Script';
  src: url('../../fonts/Rouge_Script/RougeScript-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Varta';
  src: url('../../fonts/Varta/Varta-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: normal;
  font-style: normal;
}

$default_font: system-ui, -apple-system;
$base_font: "Varta", sans-serif;
$fancy_font: "Rouge Script", sans-serif;
