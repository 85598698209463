/* Define application colors here */

$light: white;
$dark: black;

// Colors
$colors: (
  'pink': #ff1e65,
  'red': #ad0a1a,
  'orange': #ff850b,
  'yellow': #ffc61e,
  'green': #97ff7a,
  'teal': #02c6f2,
  'blue': #7096ff,
  'indigo': #060351,
  'violet': #45149b,
  'purple': #c19eff
);

// Export colors list for React
:export {
  @each $key, $val in $colors {
    #{$key}: #{$val};
  }
}
