/* Basic content level html elements go here */
body {
  font-family: $base_font;
  font-size: calc(10px + 1.5vmin);
  line-height: 1.4;

  @media screen {
    background-image: url("../../images/noise-texture.png");
  }
}

h2 {
  @include dotted-border;
  font-size: 200%;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  font-size: 130%;
  text-align: center;
}

small {
  font-size: 72%;
}

a {
  color: color('red');
  text-decoration: none;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    color: color('violet');
    text-decoration: underline;
  }

  &:focus {
    outline-color: color('violet');
    outline-offset: 0.4rem;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 1em auto;
}

button {
  background-color: color('pink');
  border: 0 none;
  border-radius: 2.25rem;
  @include inset-box-shadow(color('pink'));
  color: $light;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1.25rem 1.75rem;
  text-transform: uppercase;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    background-color: color('red');
    @include inset-box-shadow(color('red'));
  }

  &:focus {
    outline-color: color('red');
    outline-offset: 0.5rem;
  }
}

li {
  margin-bottom: 0.5em;
}
