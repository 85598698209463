/* Styles for the app header */

.app-header {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0 5%;
  text-align: center;

  @media screen {
    background-color: color('pink');
    background-image: url("../../images/noise-texture.png");
    @include box-shadow;
    color: $light;
    flex-direction: column;
    font-size: 130%;
  }

  h1 {
    @media screen {
      font-family: $fancy_font;
      font-size: 26vmin;
      font-weight: 400;
      line-height: 0.75;
      margin: auto 0 1vmin;
      opacity: 0.8;
      text-shadow: 0.5vmin 0.5vmin 0.5vmin scale-color(color('pink'), $lightness: -40%);

      + p {
        margin-top: 0;
        @include dotted-border($light, before);
      }
    }

    @media screen and (max-width: 1129px) {
      font-size: 32vmin;
      text-indent: -30vmin;
      transform: translateX(7vmin);
    }
  }

  p {
    @extend %narrow-section;

    &:last-of-type {
      margin-bottom: auto;
    }
  }

  a {
    color: $light;
    padding: 0.2rem 0.4rem;

    &:focus {
      outline-color: $light;
    }
  }
}

.app-main-nav {
  ul {
    @extend %flex-list;
    font-variation-settings: 'wght' 700;
    justify-content: center;
    max-width: 300px;
    text-transform: uppercase;

    @media (min-width: 550px) {
      max-width: unset;
    }

    li {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      margin: 0;
      padding: 0;

      &:after {
        content: "|";
        display: inline-flex;
        font-size: 50%;
        margin: -0.5vmin 3vmin 0;
      }

      &:nth-child(2):after,
      &:last-child:after {
        display: none;
      }

      @media (min-width: 550px) {
        &:nth-child(2):after {
          display: block;
        }
      }
    }
  }
}
