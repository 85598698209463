/* Styles specific to the work section */
.work {
  background-color: scale-color(color('orange'), $lightness: 60%);
  background-image: url("../../images/noise-texture.png");
  @include box-shadow;

  h2,
  h3,
  p {
    @extend %narrow-section;
  }
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -1%;
  padding: 0;
  @include wide-section;

  small {
    display: block;
    text-transform: uppercase;

    &.client-name {
      margin-bottom: 0.25rem;
    }
    &.project__status {
      margin-bottom: 0.5rem;
    }
  }

  a {
    &:after {
      border-radius: 1rem;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }

    &:hover:after,
    &:focus:after {
      background: rgba($light, 0.1);
      box-shadow: 0 0 8px 0 rgba(scale-color(color('orange'), $lightness: -25%), 0.3);
    }
  }

  .project {
    background: rgba($light, 0.2);
    border: 1px solid rgba(color('red'), 0.2);
    border-radius: 1rem;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 1%;
    padding: 1rem;
    position: relative;

    @media (min-width: 45rem) {
      flex: 1 1 20rem;
    }

    @media (min-width: 45rem) and (max-width: 69.5rem) {
      &:first-of-type {
        flex: 1 1 100%;
        max-height: 300px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: calc(2% + 2.19rem);

        .project__img {
          grid-column: 1 / span 1;
          grid-row: 1 / span 4;
          justify-self: end;

          &--mobile {
            margin-top: 5%;
          }
        }

        .project__name,
        .project__description {
          grid-column: 2 / span 1;
        }
        .project__name {
          grid-row: 1 / span 1;
        }
        .project__description {
          grid-row: 2 / span 1;
        }
        .project__status {
          grid-row: 3 / span 1;
        }
        .project__tags {
          grid-row: 4 / span 1;
        }
      }
    }

    @media screen and (min-width: 1400px) {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__name,
    &__description {
      line-height: 1.3;
      margin: 0 0 0.5rem;
    }

    &__img {
      margin: 0;

      &--mobile {
        align-self: flex-end;
        @include box-shadow;
        margin: -48% 5% 0 0;
        position: relative;
        transform: rotate(4deg);
        width: 30%;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      font-size: 70%;
      justify-content: flex-start;
      margin: auto -0.25rem 0;
      padding: 0;

      li {
        background: color('red');
        border-radius: 0.5rem;
        color: $light;
        display: unset;
        flex: 0 0 auto;
        line-height: 1;
        margin: 0.25rem;
        padding: 0.6rem 0.6rem 0.3rem;
      }
    }
  }
}

.client-list {
  column-gap: 2%;
  columns: 20rem;
  font-size: 83%;
  list-style: none;
  margin-top: -0.6rem;
  padding: 0;
  @include wide-section;

  li {
    display: block;
    margin: 0;
    padding: 0.6rem 0;
  }
}
